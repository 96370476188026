import { analytics, logEvent } from '../firebase';

export const trackPodcastPlay = (episodeId, episodeTitle) => {
  logEvent(analytics, 'podcast_play', {
    episode_id: episodeId,
    episode_title: episodeTitle
  });
};

export const trackPodcastProgress = (episodeId, progress) => {
  logEvent(analytics, 'podcast_progress', {
    episode_id: episodeId,
    progress: progress // percentage of episode played
  });
};

export const trackArticleView = (articleId, articleTitle) => {
  logEvent(analytics, 'article_view', {
    article_id: articleId,
    article_title: articleTitle
  });
};

export const trackUserComment = (contentId, contentType) => {
  logEvent(analytics, 'user_comment', {
    content_id: contentId,
    content_type: contentType // 'podcast' or 'article'
  });
};

export const trackUserEngagement = (duration) => {
  logEvent(analytics, 'user_engagement', {
    duration: duration // time spent on site in seconds
  });
};

export const trackExternalLink = (linkType) => {
  logEvent(analytics, 'external_link_click', {
    link_type: linkType // e.g., 'spotify', 'apple_podcasts'
  });
};