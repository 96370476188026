import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

const ArticlesContext = createContext();

export const useArticles = () => {
  return useContext(ArticlesContext);
};

export const ArticlesProvider = ({ children }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const q = query(collection(db, 'articles'), orderBy('publishDate', 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const articlesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setArticles(articlesList);
      setLoading(false);
    }, (error) => {
      setError(error.message);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <ArticlesContext.Provider value={{ articles, loading, error, setArticles }}>
      {children}
    </ArticlesContext.Provider>
  );
};