import React from 'react';
import { Helmet } from 'react-helmet';

const LocalSEOMarkup = () => {
  const podcastSchema = {
    '@context': 'https://schema.org',
    '@type': 'PodcastSeries',
    'name': 'מכבי מבעד למראה',
    'description': 'הפודקאסט המוביל על מכבי תל אביב בכדורסל',
    'url': 'https://www.maccabi-through.com',
    'image': `${process.env.PUBLIC_URL}/images/podcast-logo.jpg`,
    'author': {
      '@type': 'Organization',
      'name': 'מכבי מבעד למראה',
      'logo': {
        '@type': 'ImageObject',
        'url': `${process.env.PUBLIC_URL}/images/logo.png`
      }
    },
    'publisher': {
      '@type': 'Organization',
      'name': 'מכבי מבעד למראה',
      'logo': {
        '@type': 'ImageObject',
        'url': `${process.env.PUBLIC_URL}/images/logo.png`
      }
    },
    'inLanguage': 'he-IL',
    'genre': 'Sports',
    'contentLocation': {
      '@type': 'Country',
      'name': 'Israel'
    },
    'sameAs': [
      'https://www.facebook.com/profile.php?id=61553770276592',
      'https://x.com/Maccabi_Looking',
      'https://www.instagram.com/maccabi_looking/'
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(podcastSchema)}
      </script>
    </Helmet>
  );
};

export default LocalSEOMarkup;