import React, { useState, useEffect, useCallback } from 'react';
import { FaPlay, FaPause, FaChevronDown, FaChevronUp, FaSpotify, FaPodcast } from 'react-icons/fa';
import { stripHtmlTags } from '../utils';
import { trackPodcastProgress, trackExternalLink } from '../utils/analytics';
import SEO from './SEO';

const PodcastEpisodeList = ({ episodes, onPlayEpisode, currentEpisode, playingEpisodeId, currentTime, duration }) => {
  const [visibleEpisodes, setVisibleEpisodes] = useState([]);
  const [expandedEpisode, setExpandedEpisode] = useState(null);
  const [showAllEpisodes, setShowAllEpisodes] = useState(false);
  const [lastTrackedProgress, setLastTrackedProgress] = useState(0);

  useEffect(() => {
    console.log('Episodes received in PodcastEpisodeList:', episodes);
    if (episodes && episodes.length > 0) {
      setVisibleEpisodes(episodes.slice(0, 3));
    }
  }, [episodes]);

  useEffect(() => {
    if (currentEpisode && playingEpisodeId === currentEpisode.guid[0]) {
      const progress = Math.floor((currentTime / duration) * 100);
      if (progress % 25 === 0 && progress !== lastTrackedProgress) {
        trackPodcastProgress(currentEpisode.guid[0], progress);
        setLastTrackedProgress(progress);
      }
    }
  }, [currentEpisode, playingEpisodeId, currentTime, duration, lastTrackedProgress]);

  const toggleDescription = useCallback((episodeId) => {
    setExpandedEpisode(prevId => prevId === episodeId ? null : episodeId);
  }, []);

  const loadAllEpisodes = useCallback(() => {
    setVisibleEpisodes(episodes);
    setShowAllEpisodes(true);
  }, [episodes]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handlePlayEpisode = (episode) => {
    console.log('handlePlayEpisode called with episode:', episode);
    onPlayEpisode(episode);
  };

  const isEpisodePlaying = (episode) => {
    return playingEpisodeId === episode.guid[0];
  };

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'PodcastSeries',
    'name': 'מכבי מבעד למראה',
    'description': 'הפודקאסט המוביל על מכבי תל אביב בכדורסל',
    'url': 'https://www.maccabi-through.com',
    'webFeed': 'https://feeds.buzzsprout.com/2247371.rss',
    'author': {
      '@type': 'Organization',
      'name': 'מכבי מבעד למראה'
    },
    'episode': episodes.map(episode => ({
      '@type': 'PodcastEpisode',
      'name': episode.title,
      'url': `https://www.maccabi-through.com/episode/${episode.guid[0]}`,
      'datePublished': episode.pubDate,
      'description': stripHtmlTags(episode.description)
    }))
  };

  if (!episodes || episodes.length === 0) {
    console.log('No episodes available');
    return <div>No episodes available</div>;
  }

  return (
    <>
      <SEO 
        title="כל הפרקים | מכבי מבעד למראה"
        description="האזינו לכל הפרקים של הפודקאסט המוביל על מכבי תל אביב בכדורסל"
        canonicalUrl="https://www.maccabi-through.com/episodes"
        ogImage="https://www.maccabi-through.com/images/podcast-og-image.jpg"
        structuredData={structuredData}
      />
      <div className="container mx-auto px-4 py-16 rtl" id="episodes">
        <h2 className="text-3xl font-bold mb-6">פרקים נוספים</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {visibleEpisodes.map((episode) => {
            const episodePlaying = isEpisodePlaying(episode);
            const isCurrentEpisode = currentEpisode && currentEpisode.guid[0] === episode.guid[0];
            const progress = isCurrentEpisode ? (currentTime / duration) * 100 : 0;

            console.log(`Rendering episode: ${episode.title}, Playing: ${episodePlaying}`);

            return (
              <div key={episode.guid[0]} className="bg-white rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-2xl border border-primary">
                <div className="p-6">
                  <h3 className="text-xl font-bold text-primary mb-2">{episode.title}</h3>
                  <div className="text-sm text-gray-600 mb-4">
                    <span>{new Date(episode.pubDate).toLocaleDateString('he-IL')}</span>
                  </div>
                  <p className={`text-text mb-4 ${expandedEpisode === episode.guid[0] ? '' : 'line-clamp-3'}`}>
                    {stripHtmlTags(episode.description)}
                  </p>
                  <div className="flex items-center justify-between">
                    {episode.enclosure && episode.enclosure.url ? (
                      <button 
                        onClick={() => handlePlayEpisode(episode)}
                        className="bg-primary text-white px-4 py-2 rounded-full hover:bg-secondary transition duration-300"
                      >
                        {episodePlaying ? <FaPause className="ml-2 inline" /> : <FaPlay className="ml-2 inline" />}
                        <span>{episodePlaying ? 'השהה' : 'נגן'}</span>
                      </button>
                    ) : (
                      <span className="text-red-600">URL אודיו אינו זמין</span>
                    )}
                    <div className="flex space-x-2">
                      <a href="https://open.spotify.com/show/3EKetH3r5ixnCER3uSDNgw?si=30e0453e09e94bab" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-primary hover:text-secondary ml-2"
                        onClick={() => trackExternalLink('spotify')}>
                        <FaSpotify size={24} />
                      </a>
                      <a href="https://podcasts.apple.com/il/podcast/%D7%9E%D7%9B%D7%91%D7%99-%D7%9E%D7%91%D7%A2%D7%93-%D7%9C%D7%9E%D7%A8%D7%90%D7%94-%D7%A4%D7%95%D7%93%D7%A7%D7%90%D7%A1%D7%98-%D7%94%D7%99%D7%95%D7%A8%D7%95%D7%9C%D7%99%D7%92-%D7%94%D7%97%D7%93%D7%A9-%D7%A9%D7%9C-%D7%9E%D7%9B%D7%91%D7%99-%D7%AA%D7%9C-%D7%90%D7%91%D7%99%D7%91/id1716755944" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-primary hover:text-secondary"
                        onClick={() => trackExternalLink('apple_podcasts')}>
                        <FaPodcast size={24} />
                      </a>
                    </div>
                  </div>
                  {isCurrentEpisode && episodePlaying && (
                    <div className="mt-4">
                      <div className="bg-gray-200 rounded-full h-2">
                        <div 
                          className="bg-blue-600 h-2 rounded-full transition-all duration-300 ease-in-out" 
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                      <div className="text-sm text-gray-500 mt-1">
                        {formatTime(currentTime)} / {formatTime(duration)}
                      </div>
                    </div>
                  )}
                  <button
                    onClick={() => toggleDescription(episode.guid[0])}
                    className="text-primary hover:text-secondary transition duration-300 flex items-center mt-4"
                  >
                    {expandedEpisode === episode.guid[0] ? (
                      <>
                        <span className="ml-1">פחות פרטים</span>
                        <FaChevronUp />
                      </>
                    ) : (
                      <>
                        <span className="ml-1">פרטים נוספים</span>
                        <FaChevronDown />
                      </>
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {!showAllEpisodes && episodes.length > 3 && (
          <div className="text-center mt-8">
            <button 
              onClick={loadAllEpisodes}
              className="bg-primary text-white px-6 py-3 rounded-full hover:bg-secondary hover:text-primary transition duration-300 font-bold"
            >
              טען את כל הפרקים
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(PodcastEpisodeList);