import axios from 'axios';
import xml2js from 'xml2js';

const fetchRSS = async () => {
  try {
    const response = await axios.get('https://feeds.buzzsprout.com/2247371.rss', {
      timeout: 10000, // Increased timeout to 10 seconds
      headers: {
        'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36'
      }
    });

    const parser = new xml2js.Parser({
      explicitArray: false,
      mergeAttrs: true
    });

    const { rss } = await parser.parseStringPromise(response.data);
    
    const items = rss.channel.item;
    console.log('Number of items:', items.length);
    console.log('First item:', items[0]);

    return items;
  } catch (error) {
    console.error('Error fetching RSS:', error);
    let errorMessage = 'Failed to fetch RSS feed';
    let statusCode = 500;

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      errorMessage = `Server responded with status ${error.response.status}`;
      statusCode = error.response.status;
    } else if (error.request) {
      // The request was made but no response was received
      errorMessage = 'No response received from server';
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage = error.message;
    }

    return {
      statusCode: statusCode,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ error: errorMessage, details: error.message })
    };
  }
};

export default fetchRSS;