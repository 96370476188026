import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logo from '../assets/logo.png';

const HeroSection = ({ onPlayLatestEpisodeAndScroll }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="relative bg-gradient-to-r from-blue-600 to-yellow-400 text-white overflow-hidden" id="home">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12 lg:py-16">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div 
            className={`transition-all duration-1000 ease-out transform ${
              isVisible ? 'opacity-100 rotate-0' : 'opacity-0 -rotate-180'
            } md:w-1/3`}
          >
            <img 
              src={logo} 
              alt="מכבי מבעד למראה לוגו" 
              className="mx-auto w-32 h-32 md:w-40 md:h-40 object-contain filter drop-shadow-lg hover:drop-shadow-2xl transition-all duration-300"
            />
          </div>
          <div className="md:w-2/3 text-center md:text-right mt-4 md:mt-0">
            <h1 className="text-3xl font-extrabold text-white sm:text-4xl md:text-5xl font-sans">
              ברוכים הבאים ל-<span className="block">מכבי מבעד למראה</span>
            </h1>
            <p className="mt-2 max-w-md mx-auto md:mx-0 text-sm text-gray-100 sm:text-base md:text-lg">
              הצטרפו אלינו לדיונים מעמיקים על מכבי, ההיסטוריה, ההווה והעתיד.
            </p>
            <div className="mt-4 sm:mt-6">
              <button
                onClick={onPlayLatestEpisodeAndScroll}
                className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50 transition duration-300 hover:shadow-lg"
              >
                האזינו עכשיו
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroSection.propTypes = {
  onPlayLatestEpisodeAndScroll: PropTypes.func.isRequired,
};

export default React.memo(HeroSection);
