import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import SEO from './SEO';

// Import your logo
import logo from '../assets/logo.png'; // Adjust the path as necessary

const ArticleDetail = () => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const docRef = doc(db, 'articles', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setArticle({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching article:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) return <div className="container mx-auto p-4">טוען את הכתבה...</div>;
  if (!article) return <div className="container mx-auto p-4">הכתבה לא נמצאה.</div>;

  // Use the article's image if available, otherwise use the logo
  const ogImage = article.imageUrl || `https://www.maccabi-through.com${logo}`;

  return (
    <>
      <SEO 
        title={article.title}
        description={article.content.substring(0, 160)}
        canonicalUrl={`https://www.maccabi-through.com/article/${id}`}
        ogImage={ogImage}
      />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-primary mb-4">{article.title}</h1>
        {article.imageUrl && (
          <img 
            src={article.imageUrl} 
            alt={article.title} 
            className="w-full max-h-96 object-cover rounded-lg mb-4" 
          />
        )}
        <p className="text-sm text-gray-600 mb-4">
          הועלה בתאריך: {article.createdAt?.toDate().toLocaleDateString('he-IL')}
        </p>
        <div className="prose max-w-none">{article.content}</div>
      </div>
    </>
  );
};

export default ArticleDetail;