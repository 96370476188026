import React, { useState, useEffect } from 'react';
import { FaPlay, FaPause, FaChevronDown, FaChevronUp, FaSpotify, FaPodcast } from 'react-icons/fa';
import { stripHtmlTags } from '../utils';
import { trackPodcastPlay, trackPodcastProgress, trackExternalLink } from '../utils/analytics';
import SEO from './SEO';

const FeaturedEpisode = ({ episode, isPlaying, onPlayPause, currentTime, duration, hasStartedPlaying }) => {
  const [expanded, setExpanded] = useState(false);
  const [lastTrackedProgress, setLastTrackedProgress] = useState(0);

  useEffect(() => {
    console.log('Featured episode:', episode);
  }, [episode]);

  useEffect(() => {
    if (hasStartedPlaying) {
      const progress = Math.floor((currentTime / duration) * 100);
      if (progress % 25 === 0 && progress !== lastTrackedProgress) {
        trackPodcastProgress(episode.guid[0], progress);
        setLastTrackedProgress(progress);
      }
    }
  }, [currentTime, duration, episode, hasStartedPlaying, lastTrackedProgress]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const progress = duration ? (currentTime / duration) * 100 : 0;

  const handlePlayPause = () => {
    if (!isPlaying) {
      trackPodcastPlay(episode.guid[0], episode.title);
    }
    onPlayPause();
  };

  if (!episode) {
    return <div>No featured episode available</div>;
  }

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'PodcastEpisode',
    'name': episode.title,
    'description': stripHtmlTags(episode.description),
    'datePublished': episode.pubDate,
    'duration': episode.enclosure?.length ? `PT${Math.floor(episode.enclosure.length / 60)}M` : undefined,
    'url': `https://www.maccabi-through.com/episode/${episode.guid[0]}`,
    'associatedMedia': {
      '@type': 'MediaObject',
      'contentUrl': episode.enclosure?.url
    },
    'partOfSeries': {
      '@type': 'PodcastSeries',
      'name': 'מכבי מבעד למראה',
      'url': 'https://www.maccabi-through.com',
      'webFeed': 'https://feeds.buzzsprout.com/2247371.rss',
      'author': {
        '@type': 'Organization',
        'name': 'מכבי מבעד למראה'
      }
    }
  };

  return (
    <>
      <SEO 
        title={`${episode.title} | מכבי מבעד למראה`}
        description={stripHtmlTags(episode.description).substring(0, 160)}
        canonicalUrl={`https://www.maccabi-through.com/episode/${episode.guid[0]}`}
        ogImage="https://www.maccabi-through.com/images/podcast-og-image.jpg"
        structuredData={structuredData}
      />
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6">
          <h3 className="text-xl font-bold text-primary mb-2">פרק מומלץ</h3>
          <h4 className="text-lg mb-2">{episode.title}</h4>
          <p className={`mb-4 text-sm ${expanded ? '' : 'line-clamp-3'}`}>
            {stripHtmlTags(episode.description)}
          </p>
          <div className="flex justify-between items-center">
            {episode.enclosure && episode.enclosure.url ? (
              <button 
                onClick={handlePlayPause}
                className="bg-primary text-white px-4 py-2 rounded-full hover:bg-secondary transition duration-300"
              >
                {isPlaying ? <FaPause className="ml-2 inline" /> : <FaPlay className="ml-2 inline" />}
                <span>{isPlaying ? 'השהה' : 'נגן'}</span>
              </button>
            ) : (
              <span className="text-red-600">URL אודיו אינו זמין</span>
            )}
            <div className="flex space-x-2">
              <a href="https://open.spotify.com/show/3EKetH3r5ixnCER3uSDNgw?si=30e0453e09e94bab" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-primary hover:text-secondary ml-2"
                onClick={() => trackExternalLink('spotify')}>
                <FaSpotify size={24} />
              </a>
              <a href="https://podcasts.apple.com/il/podcast/%D7%9E%D7%9B%D7%91%D7%99-%D7%9E%D7%91%D7%A2%D7%93-%D7%9C%D7%9E%D7%A8%D7%90%D7%94-%D7%A4%D7%95%D7%93%D7%A7%D7%90%D7%A1%D7%98-%D7%94%D7%99%D7%95%D7%A8%D7%95%D7%9C%D7%99%D7%92-%D7%94%D7%97%D7%93%D7%A9-%D7%A9%D7%9C-%D7%9E%D7%9B%D7%91%D7%99-%D7%AA%D7%9C-%D7%90%D7%91%D7%99%D7%91/id1716755944" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-primary hover:text-secondary"
                onClick={() => trackExternalLink('apple_podcasts')}>
                <FaPodcast size={24} />
              </a>
            </div>
          </div>
          <button
            onClick={() => setExpanded(!expanded)}
            className="text-primary hover:text-secondary transition duration-300 flex items-center mt-4"
          >
            {expanded ? (
              <>
                <span className="ml-1">פחות פרטים</span>
                <FaChevronUp />
              </>
            ) : (
              <>
                <span className="ml-1">פרטים נוספים</span>
                <FaChevronDown />
              </>
            )}
          </button>
          {hasStartedPlaying && (
            <div className="mt-4">
              <div className="bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-blue-600 h-2 rounded-full transition-all duration-300 ease-in-out" 
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <div className="text-sm text-gray-500 mt-1">
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FeaturedEpisode;