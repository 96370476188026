import { useEffect } from 'react';
import { analytics, logEvent } from '../firebase';

export const useAnalytics = () => {
  const trackEvent = (eventName, eventParams = {}) => {
    logEvent(analytics, eventName, eventParams);
  };

  const trackPageView = (pageName) => {
    trackEvent('page_view', { page_name: pageName });
  };

  useEffect(() => {
    trackPageView(window.location.pathname);
  }, []);

  return { trackEvent, trackPageView };
};