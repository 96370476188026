import React from 'react';

const About = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">אודות מכבי מבעד למראה</h1>
      <p className="mb-4">
        מכבי מבעד למראה הוא הפודקאסט המוביל העוסק במכבי תל אביב בכדורסל. 
        אנחנו מביאים לכם את הניתוחים העמוקים ביותר, הראיונות המרתקים והתובנות הייחודיות על הקבוצה האהובה.
      </p>
      <p>
        הצטרפו אלינו בכל שבוע לדיון מעמיק על המשחקים האחרונים, הצפי לעתיד, וכל מה שקורה מאחורי הקלעים במועדון.
      </p>
    </div>
  );
};

export default About;