import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="bg-primary text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-start">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h2 className="text-2xl font-bold mb-2">מכבי מבעד למראה</h2>
            <p className="text-sm">הפודקאסט המוביל על מכבי תל אביב בכדורסל</p>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-2">קישורים מהירים</h3>
            <ul className="flex flex-wrap">
              <li className="ml-4 mb-1">
                <button onClick={handleScrollToTop} className="hover:text-secondary transition duration-300">
                  דף הבית
                </button>
              </li>
              <li className="ml-4 mb-1">
                <Link to="/about" className="hover:text-secondary transition duration-300">
                  אודות
                </Link>
              </li>
              <li className="mb-1">
                <Link to="/contact" className="hover:text-secondary transition duration-300">
                  צור קשר
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <h3 className="text-lg font-semibold mb-2 text-right">עקבו אחרינו</h3>
            <div className="flex space-x-3 space-x-reverse mt-2">
              <a href="https://x.com/Maccabi_Looking" target="_blank" rel="noopener noreferrer" className="text-white hover:text-secondary transition duration-300">
                <FaXTwitter size={24} />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61553770276592" target="_blank" rel="noopener noreferrer" className="text-white hover:text-secondary transition duration-300">
                <FaFacebookF size={24} />
              </a>
              <a href="https://www.instagram.com/maccabi_looking/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-secondary transition duration-300">
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
        </div>
        <div className="border-t border-white/20 mt-8 pt-8 text-center">
          <p>&copy; {new Date().getFullYear()} מכבי מבעד למראה. כל הזכויות שמורות.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;