import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const LatestArticle = () => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestArticle = async () => {
      try {
        const q = query(collection(db, 'articles'), orderBy('createdAt', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setArticle({ id: doc.id, ...doc.data() });
        }
      } catch (error) {
        console.error('Error fetching latest article:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchLatestArticle();
  }, []);

  if (loading) return <div className="bg-white p-6 rounded-lg shadow-lg">טוען את הכתבה האחרונה...</div>;
  if (!article) return <div className="bg-white p-6 rounded-lg shadow-lg">אין כתבות זמינות.</div>;

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-xl font-bold text-primary mb-2">כתבה מומלצת</h3>
        <h4 className="text-lg mb-4">{article.title}</h4>
        {article.imageUrl && (
          <div className="mb-4">
            <img 
              src={article.imageUrl} 
              alt={article.title} 
              className="w-full h-48 object-cover rounded-lg" 
              onError={(e) => {
                console.error('Error loading image:', e);
                e.target.style.display = 'none';
              }}
            />
          </div>
        )}
        <Link 
          to={`/article/${article.id}`} 
          className="bg-primary text-white px-4 py-2 rounded-full hover:bg-secondary transition duration-300 inline-block"
        >
          קרא עוד
        </Link>
      </div>
    </div>
  );
};

export default LatestArticle;