export const stripHtmlTags = (html) => {
  if (!html) return '';
    
  // Remove <p dir='rtl'> from the start and </p> from the end
  let stripped = html.replace(/^<p dir='rtl'>|<\/p>$/g, '');
    
  // Remove any remaining HTML tags
  stripped = stripped.replace(/<[^>]*>/g, '');
    
  return stripped.trim();
};