import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, orderBy, query, limit, startAfter } from 'firebase/firestore';
import { db } from '../firebase';

const ArticlesList = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // First, get the latest article
        const latestArticleQuery = query(collection(db, 'articles'), orderBy('createdAt', 'desc'), limit(1));
        const latestArticleSnapshot = await getDocs(latestArticleQuery);
        
        if (!latestArticleSnapshot.empty) {
          const latestArticle = latestArticleSnapshot.docs[0];
          
          // Then, get the next 10 articles after the latest one
          const olderArticlesQuery = query(
            collection(db, 'articles'), 
            orderBy('createdAt', 'desc'), 
            startAfter(latestArticle),
            limit(10)
          );
          const olderArticlesSnapshot = await getDocs(olderArticlesQuery);
          
          const articlesList = olderArticlesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setArticles(articlesList);
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) return <div className="bg-white p-6 rounded-lg shadow-lg">טוען כתבות...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6 text-primary">כתבות קודמות</h2>
      {articles.length === 0 ? (
        <p className="text-center">אין כתבות נוספות זמינות.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {articles.map(article => (
            <div key={article.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="p-6">
                <h4 className="text-lg mb-4">{article.title}</h4>
                {article.imageUrl && (
                  <div className="mb-4">
                    <img 
                      src={article.imageUrl} 
                      alt={article.title} 
                      className="w-full h-48 object-cover rounded-lg" 
                      onError={(e) => {
                        console.error('Error loading image:', e);
                        e.target.style.display = 'none';
                      }}
                    />
                  </div>
                )}
                <Link 
                  to={`/article/${article.id}`} 
                  className="bg-primary text-white px-4 py-2 rounded-full hover:bg-secondary transition duration-300 inline-block"
                >
                  קרא עוד
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ArticlesList;