import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

const Header = () => {
  const [user] = useAuthState(auth);

  const handleLogout = () => {
    signOut(auth);
  };

  return (
    <header className="bg-primary text-white py-4">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold">מכבי מבעד למראה</Link>
          <nav>
            <ul className="flex gap-6">
              <li><Link to="/about" className="hover:text-secondary transition duration-300">אודות</Link></li>
              <li><Link to="/articles" className="hover:text-secondary transition duration-300">כתבות קודמות</Link></li>
              {user ? (
                <>
                  <li><Link to="/admin/add-article" className="hover:text-secondary transition duration-300">הוסף כתבה</Link></li>
                  <li><button onClick={handleLogout} className="hover:text-secondary transition duration-300">התנתק</button></li>
                </>
              ) : (
                <li><Link to="/login" className="hover:text-secondary transition duration-300">התחבר</Link></li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;