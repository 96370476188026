import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import fetchRSS from './utils/rssUtils.mjs';
import { AuthProvider } from './contexts/AuthContext';
import { ArticlesProvider } from './contexts/ArticlesContext';
import { useAnalytics } from './hooks/useAnalytics';

// Component imports
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturedEpisode from './components/FeaturedEpisode';
import LatestArticle from './components/LatestArticle';
import PodcastEpisodeList from './components/PodcastEpisodeList';
import Footer from './components/Footer';
import AddArticle from './components/AddArticle';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import ArticlesList from './components/ArticlesList';
import ArticleDetail from './components/ArticleDetail';
import About from './components/About';
import SEO from './components/SEO';
import LocalSEOMarkup from './components/LocalSEOMarkup';
import Contact from './components/Contact';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppContent() {
  const [episodes, setEpisodes] = useState([]);
  const [playingEpisodeId, setPlayingEpisodeId] = useState(null);
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [user, loading] = useAuthState(auth);
  const audioRef = useRef(new Audio());
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const [isLoadingEpisodes, setIsLoadingEpisodes] = useState(true);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
  const featuredEpisodeRef = useRef(null);
  const location = useLocation();
  const { trackEvent, trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location, trackPageView]);

  useEffect(() => {
    const startTime = Date.now();

    return () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000; // in seconds
      trackEvent('time_on_site', { duration: timeSpent });
    };
  }, [trackEvent]);

  useEffect(() => {
    const loadEpisodes = async () => {
      setIsLoadingEpisodes(true);
      try {
        console.log('Fetching RSS data...');
        const rssData = await fetchRSS();
        console.log('RSS data received:', rssData);
        setEpisodes(rssData);
        setError(null);
      } catch (error) {
        console.error('Error in loadEpisodes:', error);
        setError(`Failed to load episodes: ${error.message}`);
      } finally {
        setIsLoadingEpisodes(false);
      }
    };

    loadEpisodes();

    const audio = audioRef.current;
    audio.addEventListener('timeupdate', updatePlaybackPosition);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('ended', handleAudioEnded);
    audio.addEventListener('error', handleAudioError);

    return () => {
      audio.removeEventListener('timeupdate', updatePlaybackPosition);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('ended', handleAudioEnded);
      audio.removeEventListener('error', handleAudioError);
      audio.pause();
      audio.src = '';
    };
  }, []);

  const updatePlaybackPosition = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
    console.log('Audio duration set:', audioRef.current.duration);
  };

  const handleAudioEnded = () => {
    setPlayingEpisodeId(null);
    setHasStartedPlaying(false);
    console.log('Audio playback ended');
    trackEvent('podcast_complete', { episode_id: currentEpisode?.guid[0] });
  };

  const handleAudioError = (e) => {
    console.error('Audio error:', e);
    let errorMessage = 'Error playing audio. Please try again.';
    if (e.message) {
      errorMessage += ` Error details: ${e.message}`;
    }
    setError(errorMessage);
    trackEvent('podcast_error', { episode_id: currentEpisode?.guid[0], error: e.message });
  };

  const playEpisode = useCallback((episode) => {
    console.log('Playing Episode:', episode);
    const audio = audioRef.current;
    
    if (playingEpisodeId === episode.guid[0]) {
      // If the same episode is clicked, toggle play/pause
      if (audio.paused) {
        audio.play().catch(handleAudioError);
        setPlayingEpisodeId(episode.guid[0]);
        trackEvent('podcast_resume', { episode_id: episode.guid[0] });
      } else {
        audio.pause();
        setPlayingEpisodeId(null);
        trackEvent('podcast_pause', { episode_id: episode.guid[0] });
      }
    } else {
      // If a different episode is clicked, stop the current one and play the new one
      audio.pause();
      audio.currentTime = 0;
      
      if (episode.enclosure && episode.enclosure.url) {
        console.log('Setting new audio source:', episode.enclosure.url);
        audio.src = episode.enclosure.url;
        audio.load();
        audio.play().then(() => {
          console.log('Audio playback started successfully');
          setCurrentEpisode(episode);
          setPlayingEpisodeId(episode.guid[0]);
          setHasStartedPlaying(true);
          trackEvent('podcast_play', { episode_id: episode.guid[0], episode_title: episode.title });
        }).catch(handleAudioError);
      } else {
        console.error('Invalid audio URL for episode:', episode);
        setError('This episode does not have a valid audio URL.');
      }
    }
  }, [playingEpisodeId, trackEvent]);

  const playFeaturedEpisode = useCallback(() => {
    if (episodes.length > 0) {
      playEpisode(episodes[0]);
    }
  }, [episodes, playEpisode]);

  const playLatestEpisodeAndScroll = () => {
    playFeaturedEpisode();
    if (featuredEpisodeRef.current) {
      featuredEpisodeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    trackEvent('featured_episode_play', { episode_id: episodes[0]?.guid[0] });
  };

  if (loading || isLoadingEpisodes) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <LocalSEOMarkup />
      <Header user={user} />
      <main className="flex-grow">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}
        <Routes>
          <Route path="/" element={
            <>
              <SEO 
                title="דף הבית"
                description="הפודקאסט המוביל על מכבי תל אביב בכדורסל. הצטרפו אלינו לניתוחים מעמיקים, ראיונות בלעדיים ודיונים מרתקים."
                canonicalUrl="https://www.maccabi-through.com"
                ogImage="https://www.maccabi-through.com/images/home-og-image.jpg"
              />
              <HeroSection onPlayLatestEpisodeAndScroll={playLatestEpisodeAndScroll} />
              <div className="container mx-auto px-4 py-8">
                <div className="flex flex-col md:flex-row gap-8">
                  <div className="md:w-1/2" ref={featuredEpisodeRef}>
                    {episodes.length > 0 && (
                      <FeaturedEpisode 
                        episode={episodes[0]} 
                        isPlaying={playingEpisodeId === episodes[0]?.guid[0]}
                        onPlayPause={playFeaturedEpisode}
                        currentTime={currentTime}
                        duration={duration}
                        hasStartedPlaying={hasStartedPlaying}
                      />
                    )}
                  </div>
                  <div className="md:w-1/2">
                    <LatestArticle />
                  </div>
                </div>
              </div>
              <div id="episodes">
                {episodes.length > 1 && (
                  <PodcastEpisodeList 
                    episodes={episodes.slice(1)} 
                    onPlayEpisode={playEpisode}
                    currentEpisode={currentEpisode}
                    playingEpisodeId={playingEpisodeId}
                    currentTime={currentTime}
                    duration={duration}
                  />
                )}
              </div>
            </>
          } />
          <Route path="/about" element={
            <>
              <SEO 
                title="אודות"
                description="למדו עוד על הפודקאסט 'מכבי מבעד למראה' והצוות מאחוריו."
                canonicalUrl="https://www.maccabi-through.com/about"
                ogImage="https://www.maccabi-through.com/images/about-og-image.jpg"
              />
              <About />
            </>
          } />
          <Route path="/articles" element={
            <>
              <SEO 
                title="כתבות"
                description="קראו את הכתבות האחרונות שלנו על מכבי תל אביב בכדורסל."
                canonicalUrl="https://www.maccabi-through.com/articles"
                ogImage="https://www.maccabi-through.com/images/articles-og-image.jpg"
              />
              <ArticlesList />
            </>
          } />
          <Route path="/article/:id" element={<ArticleDetail />} />
          <Route path="/login" element={
            <>
              <SEO 
                title="התחברות"
                description="התחברו לחשבון שלכם ב'מכבי מבעד למראה'."
                canonicalUrl="https://www.maccabi-through.com/login"
                ogImage="https://www.maccabi-through.com/images/login-og-image.jpg"
              />
              <Login />
            </>
          } />
          <Route path="/admin/add-article" element={
            <ProtectedRoute>
              <SEO 
                title="הוספת כתבה חדשה"
                description="הוספת כתבה חדשה למערכת 'מכבי מבעד למראה'."
                canonicalUrl="https://www.maccabi-through.com/admin/add-article"
                ogImage="https://www.maccabi-through.com/images/admin-og-image.jpg"
              />
              <AddArticle />
            </ProtectedRoute>
          } />
          <Route path="/contact" element={
            <>
              <SEO 
                title="צור קשר"
                description="צרו קשר עם צוות 'מכבי מבעד למראה'."
                canonicalUrl="https://www.maccabi-through.com/contact"
                ogImage="https://www.maccabi-through.com/images/contact-og-image.jpg"
              />
              <Contact />
            </>
          } />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        <ArticlesProvider>
          <div className="flex flex-col min-h-screen bg-background">
            <AppContent />
          </div>
        </ArticlesProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;